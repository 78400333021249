@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@font-face {
  font-family: "Manual";
  src: url("./assets/fonts/ManualExtraBoldCompressed/font.woff") format("woff"),
  url("./assets/fonts/ManualExtraBoldCompressed/font.woff") format("woff2");
}


#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  padding: 0 1.25em;
  box-sizing: border-box;
  max-width: 768px;
  margin: 0 auto;
}


