.press {
  padding: 2em 0;
}

.press-headline {
  font-family: 'Manual', sans-serif;
  font-size: 2rem;
  margin-bottom: 0.5em;
} 

p {
  font-family: 'Open Sans', sans-serif;
  line-height: 150%;
}

.date-bold {
  text-transform: uppercase;
  font-weight: bolder;
}

img {
  max-width: 100%;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
}

.press-tracks,
.press-about {
  font-family: 'Manual', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 0.5em;
}

.press-track-list {
  margin-bottom: 2em;
}

li {
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0.25em;
}

@media screen and (min-width: 768px) {
  .press-image-wrapper {
    display: flex;
    justify-content: space-between;
  }

  img {
    max-width: 49%;
  }
 }