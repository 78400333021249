header {
  position: sticky;
  top: 0;
}

ul {
  list-style-type: none;
  padding: 0; 
  margin: 0;
}

li a {
  text-decoration: none;
}

nav {
  padding: 1.5em 1.25em;
  background-color: #000;
}

.nav-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-list-logo a {
  text-transform: uppercase;
  font-family: "Manual", sans-serif;
  font-size: 1.5rem;
  color: #fff;

}

.nav-list-link a  {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #fff
}

@media screen and (min-width: 768px) {
  .nav-list-logo a {
    font-size: 2rem;
  }
}

