.not-found-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 10em;
}

.not-found-wrapper p {
  font-size: 1.25rem;
  font-family: 'Open Sans', sans-serif;
}