.footer {
  margin-top: auto;
  width: 100%;
  padding: 4em 0 2em;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.some-icons {
  margin: 0 auto;
  margin-bottom: 2em;
}

.some-icons svg {
  height: 1.5em;
  width: 1.5em;
  margin: 0 0.5em;
}

.some-icons svg path {
  fill: #fff
}

.copy-wrapper {
  color: #fff;
  margin: 0;
  font-family: "Manual", sans-serif;
  text-transform: uppercase;
}