.hero {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 65vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-container--headline {
  font-family: "Manual", sans-serif;
  letter-spacing: 0.02rem;
  color: #fff;
  font-size: 4.5rem;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .hero-container--headline {
    font-size: 8rem;
  }

  .hero {
    height: 65vh;
    background-position: 100% 27%;
  }
}

